import {RootEpic} from "../../app/store";
import {filter, map, tap} from "rxjs/operators";
import {asyncScheduler, catchError, mergeMap, Observable, of, scheduled, throwError, zip} from "rxjs";
import {AuthService} from "../../services/authService";
import {
  authenticateSession,
  authenticateSessionFailed,
  authenticateSessionSucceeded,
  loginWithApple,
  loginWithAppleFailed,
  loginWithAppleSucceeded,
  loginWithEmail, loginWithEmailAndSubmitSurvey,
  loginWithEmailFailed,
  loginWithEmailSucceeded,
  loginWithFacebook,
  loginWithFacebookFailed,
  loginWithFacebookSucceeded,
  logout,
  logoutFailed,
  logoutSucceeded,
  sendPasswordReset,
  sendPasswordResetFailed,
  sendPasswordResetSucceeded,
  signUpWithEmail,
  signUpWithEmailFailed,
  signUpWithEmailSucceeded
} from "./authSlice";
import {AuthValidationError} from "../../errors/authValidationError";
import {isValidEmail} from "../../utils/isValidEmail";
import {isValidPassword} from "../../utils/isValidPassword";
import {SignUpPayload} from "../../models/signupPayload";
import {UserService} from "../../services/userService";
import {realmService} from "../../realm/realmService";
import {UserModel} from "../../models/user";
import {fetchUserSucceeded, reset, setPrimaryLocation} from "../user/userSlice";
import {showNotification} from "../navigation/navigationSlice";
import {push} from "connected-next-router";
import {AuthResponseModel} from "../../models/authResponse";
import {ApiError} from "../../errors/apiError";
import {logErrorRx} from "../../utils/logError";
import {LocationService} from "../../services/locationService";
import {LocationModel} from "../../models/location";
import {EventLogger} from "../../utils/eventLogger";
import {AnalyticsEvent} from "../../utils/eventProperties";
import {submitSurveySucceeded} from "../survey/macroSurveySlice";

export const authenticateSessionEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(authenticateSession.match),
    mergeMap(() => {
      return AuthService.authenticateSession()
        .pipe(
          catchError(error => {
            if (error instanceof ApiError && error.code === 'UNAUTHORIZED') {
              AuthService.setAccessToken(undefined)
              return AuthService.logout()
                .pipe(mergeMap(() => throwError(() => error)))
            } else {
              return throwError(() => error)
            }
          }),
          mergeMap(authResponse => {
            AuthService.setAccessToken(authResponse)
            return AuthService.loginToRealmIfNeeded(authResponse.realmToken!)
              .pipe(map(() => authResponse))
          }),
          mergeMap(authResponse => {
            return UserService.fetchUser()
              .pipe(map(user => ({ user, authResponse })))
          }),
          tap({
            next: ({ user }) => {
              EventLogger.setUserIdentifier(user)
            },
            error: () => {
              EventLogger.setUserIdentifier()
            }
          }),
          mergeMap(({ user, authResponse }) => scheduled(
            [
              authenticateSessionSucceeded({ auth: authResponse, user }),
              fetchUserSucceeded(user),
            ],
            asyncScheduler
          )),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: authenticateSessionFailed.type,
            payload: error,
            error: true
          }))
        )
    }),
  );
}

export const signUpEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(signUpWithEmail.match),
    mergeMap(action => {
      return validate(action.payload)
        .pipe(
          mergeMap(payload => {
            const email = payload.email
            const password = payload.password
            const name = payload.name

            return AuthService.signUp({
              email,
              password,
              name
            })
          }),
          mergeMap(authResponse => {
            return configureLoggedInUser(authResponse)
              .pipe(map(result => ({ user: result.user, location: result.location, authResponse })))
          }),
          mergeMap(({ user, location, authResponse }) => {
            const actions: {payload: any, type: string}[] = [
              signUpWithEmailSucceeded({ auth: authResponse, user }),
              fetchUserSucceeded(user),
              showNotification({
                title: authResponse.isNewUser ? { key: 'auth:auth_alert_title_welcome_to_fitgenie' } : { key: 'auth:auth_alert_title_login_succeeded' },
                message: authResponse.isNewUser ? { key: 'auth:auth_alert_message_welcome_to_fitgenie' } : { key: 'auth:auth_alert_message_you_were_logged_in' }
              })
            ]
            if (location && state$.value.router.location.pathname === '/') {
              actions.push(setPrimaryLocation(location))
              actions.push(push('/store-feed'))
            }

            onPostLogin(authResponse, 'Email', user)

            return scheduled(actions, asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: signUpWithEmailFailed.type,
            payload: error,
            error: true
          }))
        )
    }),
  );
}

export const loginWithEmailEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(loginWithEmail.match),
    mergeMap(action => {
      return validateSignIn(action.payload)
        .pipe(
          mergeMap(payload => {
            return AuthService.loginWithEmail(payload)
          }),
          mergeMap(authResponse => {
            return configureLoggedInUser(authResponse)
              .pipe(map(result => ({ user: result.user, location: result.location, authResponse })))
          }),
          mergeMap(({ user, location, authResponse }) => {
            const actions: {payload: any, type: string}[] = [
              loginWithEmailSucceeded({ auth: authResponse, user }),
              fetchUserSucceeded(user),
              showNotification({
                title: authResponse.isNewUser ? { key: 'auth:auth_alert_title_welcome_to_fitgenie' } : { key: 'auth:auth_alert_title_login_succeeded' },
                message: authResponse.isNewUser ? { key: 'auth:auth_alert_message_welcome_to_fitgenie' } : { key: 'auth:auth_alert_message_you_were_logged_in' }
              })
            ]

            if (location && state$.value.router.location.pathname === '/') {
              actions.push(setPrimaryLocation(location))
              actions.push(push('/store-feed'))
            }

            onPostLogin(authResponse, 'Email', user)

            return scheduled(actions, asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: loginWithEmailFailed.type,
            payload: error,
            error: true
          }))
        )
    }),
  );
}

export const loginWithFacebookEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(loginWithFacebook.match),
    mergeMap(() => {
      return AuthService.loginWithFacebook()
        .pipe(
          mergeMap(authResponse => {
            return configureLoggedInUser(authResponse)
              .pipe(map(result => ({ user: result.user, location: result.location, authResponse })))
          }),
          mergeMap(({ user, location, authResponse }) => {
            const actions: {payload: any, type: string}[] = [
              loginWithFacebookSucceeded({ auth: authResponse, user }),
              fetchUserSucceeded(user),
              showNotification({
                title: authResponse.isNewUser ? { key: 'auth:auth_alert_title_welcome_to_fitgenie' } : { key: 'auth:auth_alert_title_login_succeeded' },
                message: authResponse.isNewUser ? { key: 'auth:auth_alert_message_welcome_to_fitgenie' } : { key: 'auth:auth_alert_message_you_were_logged_in' }
              })
            ]
            if (location && state$.value.router.location.pathname === '/') {
              actions.push(setPrimaryLocation(location))
              actions.push(push('/store-feed'))
            }

            onPostLogin(authResponse, 'Facebook', user)

            return scheduled(actions, asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: loginWithFacebookFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const loginWithAppleEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(loginWithApple.match),
    mergeMap(() => {
      return AuthService.loginWithApple()
        .pipe(
          mergeMap(authResponse => {
            return configureLoggedInUser(authResponse)
              .pipe(map(result => ({ user: result.user, location: result.location, authResponse })))
          }),
          mergeMap(({ user, location, authResponse }) => {
            const actions: {payload: any, type: string}[] = [
              loginWithAppleSucceeded({ auth: authResponse, user }),
              fetchUserSucceeded(user),
              showNotification({
                title: authResponse.isNewUser ? { key: 'auth:auth_alert_title_welcome_to_fitgenie' } : { key: 'auth:auth_alert_title_login_succeeded' },
                message: authResponse.isNewUser ? { key: 'auth:auth_alert_message_welcome_to_fitgenie' } : { key: 'auth:auth_alert_message_you_were_logged_in' }
              })
            ]
            if (location && state$.value.router.location.pathname === '/') {
              actions.push(setPrimaryLocation(location))
              actions.push(push('/store-feed'))
            }

            onPostLogin(authResponse, 'Apple', user)

            return scheduled(actions, asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: loginWithAppleFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const sendPasswordResetEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(sendPasswordReset.match),
    mergeMap(action => {
      return validateEmail(action.payload.email)
        .pipe(
          mergeMap(email => {
            return AuthService.sendPasswordResetRequest({
              email,
            })
          }),
          mergeMap(() => {
            return scheduled(
              [
                sendPasswordResetSucceeded(),
                showNotification({
                  title: { key: 'auth:auth_alert_title_password_reset' },
                  message: { key: 'auth:auth_alert_message_check_email' }
                })
              ],
              asyncScheduler
            )
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: sendPasswordResetFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  );
}

export const logoutEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(logout.match),
    mergeMap(action => {
      return AuthService.logout()
        .pipe(
          mergeMap(() => {
            EventLogger.logout()
            AuthService.setAccessToken(undefined)
            return scheduled(
              [
                logoutSucceeded(),
                reset(),
                push('/'),
                showNotification({
                  title: { key: 'auth:auth_alert_title_logout_succeeded' },
                  message: { key: 'auth:auth_alert_message_you_have_been_logged_out' }
                })
              ],
              asyncScheduler
            )
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: logoutFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const configureLoggedInUser = (authResponse: AuthResponseModel, shouldFetchLocation: boolean = true): Observable<{ user: UserModel | null, location: LocationModel | null }> => {
  AuthService.setAccessToken(authResponse)

  return realmService.login(authResponse.realmToken!)
    .pipe(
      mergeMap(() => {
        const locationObservable = (shouldFetchLocation ? LocationService.fetchPrimaryDeliveryLocation() : of(null))
          .pipe(catchError(() => of(null)))
        return zip([UserService.fetchUser(), locationObservable])
          .pipe(
            tap({
              next: ([user, location]) => {
                user && EventLogger.updateGlobalPropertyWithUser(user)
                location && EventLogger.updateGlobalPropertiesWithLocation(location)
              }
            }),
            map(([user, location]) => ({ user, location }))
          )
      }),
    )
}

export const onPostLogin = (authResponse: AuthResponseModel, method: 'Email' | 'Apple' | 'Facebook', user?: UserModel | null) => {
  if (authResponse.isNewUser && user) {
    EventLogger.alias(user)
    EventLogger.logEvent(new AnalyticsEvent({ eventName: 'Account Created' }))
  } else if (user) {
    EventLogger.setUserIdentifier(user)
    EventLogger.logEvent(new AnalyticsEvent({ eventName: 'Login', method }))
  }
}

const validateEmail = (email?: string | null): Observable<string> => {
  if (!email) {
    return throwError(() => new AuthValidationError("EMAIL_IS_EMPTY"))
  } else if (!isValidEmail(email)) {
    return throwError(() => new AuthValidationError("INVALID_EMAIL"))
  } else {
    return of(email)
  }
}

const validateSignIn = (payload: SignUpPayload) => {
  if (!payload.email) {
    return throwError(() => new AuthValidationError("EMAIL_IS_EMPTY"))
  } else if (!payload.password) {
    return throwError(() => new AuthValidationError("PASSWORD_IS_EMPTY"))
  } else {
    return of(payload)
  }
}

export const validate = (payload: SignUpPayload) => {
  if (!payload.email) {
    return throwError(() => new AuthValidationError("EMAIL_IS_EMPTY"))
  } else if (!payload.password) {
    return throwError(() => new AuthValidationError("PASSWORD_IS_EMPTY"))
  } else if (!isValidEmail(payload.email)) {
    return throwError(() => new AuthValidationError("INVALID_EMAIL"))
  } else if (payload.password.length < 6) {
    return throwError(() => new AuthValidationError("INVALID_PASSWORD_LENGTH"))
  } else if (!isValidPassword(payload.password)) {
    return throwError(() => new AuthValidationError("INVALID_PASSWORD"))
  } else {
    return of(payload)
  }
}